import React from 'react';
import {
    CompassOutlined,
    DashboardOutlined,
    FormOutlined,
    HomeOutlined,
    LayoutOutlined,
    LineChartOutlined,
    TableOutlined,
    UserOutlined,
    BlockOutlined, ProfileOutlined, CodeSandboxOutlined,
} from '@ant-design/icons';
import {ReactComponent as NftIcon} from '@app/assets/icons/nft-icon.svg';

export interface SidebarNavigationItem {
    title: string;
    key: string;
    url?: string;
    children?: SidebarNavigationItem[];
    icon?: React.ReactNode;
}

export const sidebarNavigation: SidebarNavigationItem[] = [
    {
        title: 'common.homeTitle',
        key: 'home-page',
        url: '/',
        icon: <HomeOutlined/>,
    },
    {
        title: 'Vehicles',
        key: 'vehicles',
        icon: <ProfileOutlined/>,
        url: '/dashboard/vehicles',
    },
    // {
    //     title: 'Development',
    //     key: 'development',
    //     icon: <CodeSandboxOutlined/>,
    //     url: '/dashboard/development',
    // },
    {
        title: 'Profile',
        key: 'profile',
        icon: <UserOutlined/>,
        url: '/dashboard/profile/security-settings',
    },
    // {
    //     title: 'common.apps',
    //     key: 'apps',
    //     icon: <HomeOutlined/>,
    //     children: [
    //         {
    //             title: 'common.feed',
    //             key: 'feed',
    //             url: '/dashboard/apps/feed',
    //         },
    //         {
    //             title: 'common.kanban',
    //             key: 'kanban',
    //             url: '/dashboard/apps/kanban',
    //         },
    //     ],
    // },
    // {
    //     title: 'common.ui',
    //     key: 'ui',
    //     icon: <BlockOutlined/>,
    //     children: [
    //         {
    //             title: 'common.alert',
    //             key: 'alert',
    //             url: '/dashboard/ui-components/alert',
    //         },
    //         {
    //             title: 'common.avatar',
    //             key: 'avatar',
    //             url: '/dashboard/ui-components/avatar',
    //         },
    //         {
    //             title: 'common.autocomplete',
    //             key: 'auto-complete',
    //             url: '/dashboard/ui-components/auto-complete',
    //         },
    //         {
    //             title: 'common.badge',
    //             key: 'badge',
    //             url: '/dashboard/ui-components/badge',
    //         },
    //         {
    //             title: 'common.breadcrumbs',
    //             key: 'breadcrumbs',
    //             url: '/dashboard/ui-components/breadcrumbs',
    //         },
    //         {
    //             title: 'common.button',
    //             key: 'button',
    //             url: '/dashboard/ui-components/button',
    //         },
    //         {
    //             title: 'common.checkbox',
    //             key: 'checkbox',
    //             url: '/dashboard/ui-components/checkbox',
    //         },
    //         {
    //             title: 'common.collapse',
    //             key: 'collapse',
    //             url: '/dashboard/ui-components/collapse',
    //         },
    //         {
    //             title: 'common.dateTimePicker',
    //             key: 'dateTimePicker',
    //             url: '/dashboard/ui-components/date-time-picker',
    //         },
    //         {
    //             title: 'common.dropdown',
    //             key: 'dropdown',
    //             url: '/dashboard/ui-components/dropdown',
    //         },
    //         {
    //             title: 'common.input',
    //             key: 'input',
    //             url: '/dashboard/ui-components/input',
    //         },
    //         {
    //             title: 'common.modal',
    //             key: 'modal',
    //             url: '/dashboard/ui-components/modal',
    //         },
    //         {
    //             title: 'common.notification',
    //             key: 'notification',
    //             url: '/dashboard/ui-components/notification',
    //         },
    //         {
    //             title: 'common.pagination',
    //             key: 'pagination',
    //             url: '/dashboard/ui-components/pagination',
    //         },
    //         {
    //             title: 'common.popconfirm',
    //             key: 'popconfirm',
    //             url: '/dashboard/ui-components/popconfirm',
    //         },
    //         {
    //             title: 'common.popover',
    //             key: 'popover',
    //             url: '/dashboard/ui-components/popover',
    //         },
    //         {
    //             title: 'common.progress',
    //             key: 'progress',
    //             url: '/dashboard/ui-components/progress',
    //         },
    //         {
    //             title: 'common.radio',
    //             key: 'radio',
    //             url: '/dashboard/ui-components/radio',
    //         },
    //         {
    //             title: 'common.rate',
    //             key: 'rate',
    //             url: '/dashboard/ui-components/rate',
    //         },
    //         {
    //             title: 'common.result',
    //             key: 'result',
    //             url: '/dashboard/ui-components/result',
    //         },
    //         {
    //             title: 'common.select',
    //             key: 'select',
    //             url: '/dashboard/ui-components/select',
    //         },
    //         {
    //             title: 'common.skeleton',
    //             key: 'skeleton',
    //             url: '/dashboard/ui-components/skeleton',
    //         },
    //         {
    //             title: 'common.spinner',
    //             key: 'spinner',
    //             url: '/dashboard/ui-components/spinner',
    //         },
    //         {
    //             title: 'common.steps',
    //             key: 'steps',
    //             url: '/dashboard/ui-components/steps',
    //         },
    //         {
    //             title: 'common.switch',
    //             key: 'switch',
    //             url: '/dashboard/ui-components/switch',
    //         },
    //         {
    //             title: 'common.tabs',
    //             key: 'tabs',
    //             url: '/dashboard/ui-components/tabs',
    //         },
    //         {
    //             title: 'common.upload',
    //             key: 'upload',
    //             url: '/dashboard/ui-components/upload',
    //         },
    //     ],
    // },


    // {
    //     title: 'Pages',
    //     key: 'temp',
    //     icon: <FormOutlined/>,
    //     children: [
    //         // {
    //         //     title: 'common.advancedForms',
    //         //     key: 'advanced-forms',
    //         //     url: '/dashboard/forms/advanced-forms',
    //         // },
    //         // {
    //         //     title: 'common.charts',
    //         //     key: 'charts',
    //         //     url: '/dashboard/charts',
    //         //     icon: <LineChartOutlined/>,
    //         // },
    //         // {
    //         //     title: 'common.nft-dashboard',
    //         //     key: 'nft-dashboard',
    //         //     // TODO use path variable
    //         //     url: '/dashboard/nft',
    //         //     icon: <NftIcon/>,
    //         // },
    //         // {
    //         //     title: 'common.medical-dashboard',
    //         //     key: 'medical-dashboard',
    //         //     url: '/dashboard/medical-dashboard',
    //         //     icon: <DashboardOutlined/>,
    //         // },
    //         // {
    //         //     title: 'common.dataTables',
    //         //     key: 'dataTables',
    //         //     url: '/dashboard/data-tables',
    //         //     icon: <TableOutlined/>,
    //         // },
    //         {
    //             title: 'common.serverError',
    //             key: 'serverError',
    //             url: '/dashboard/server-error',
    //         },
    //         {
    //             title: 'common.clientError',
    //             key: '404Error',
    //             url: '/dashboard/404',
    //         },
    //
    //     ],
    // },
];


// {
//   title: 'common.maps',
//   key: 'maps',
//   icon: <CompassOutlined />,
//   children: [
//     {
//       title: 'common.googleMap',
//       key: 'google-maps',
//       url: '/maps/google-maps',
//     },
//     {
//       title: 'common.leafletMap',
//       key: 'leaflet-maps',
//       url: '/maps/leaflet-maps',
//     },
//     {
//       title: 'common.reactSimpleMaps',
//       key: 'react-simple-maps',
//       url: '/maps/react-simple-maps',
//     },
//     {
//       title: 'common.pigeonMaps',
//       key: 'pigeon-maps',
//       url: '/maps/pigeon-maps',
//     },
//   ],
// },