import React from 'react';
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import {RequireAuth} from 'react-auth-kit'

// no lazy loading for auth pages to avoid flickering

const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));
import LoginPage from '@app/pages/LoginPage';
import ForgotPasswordPage from '@app/pages/ForgotPasswordPage';
import NewPasswordPage from '@app/pages/NewPasswordPage';

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import ProfileLayout from '@app/components/profile/ProfileLayout';
import {withLoading} from '@app/hocs/withLoading.hoc';
import HomePage from "@app/pages/home/HomePage";

const ServerErrorPage = React.lazy(() => import('@app/pages/ServerErrorPage'));
const Error404Page = React.lazy(() => import('@app/pages/Error404Page'));
const PersonalInfoPage = React.lazy(() => import('@app/pages/PersonalInfoPage'));
const SecuritySettingsPage = React.lazy(() => import('@app/pages/SecuritySettingsPage'));
const Logout = React.lazy(() => import('./Logout'));

// New
const VehiclesPage = React.lazy(() => import('@app/pages/VehiclesPage'))
const DevelopmentPage = React.lazy(() => import('@app/pages/DevelopmentPage'))
// New

const Home = withLoading(HomePage);
const ServerError = withLoading(ServerErrorPage);
const Error404 = withLoading(Error404Page);

// Profile
const PersonalInfo = withLoading(PersonalInfoPage);
const SecuritySettings = withLoading(SecuritySettingsPage);

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);

// New
const Vehicles = withLoading(VehiclesPage);
const Development = withLoading(DevelopmentPage);


export const DASHBOARD_PATH = '/dashboard';


export const AppRouter: React.FC = () => {
    const protectedLayout = (
        <RequireAuth loginPath={'/auth/login'}>
            <MainLayout/>
        </RequireAuth>
    );

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home/>}/>

                <Route path={DASHBOARD_PATH} element={protectedLayout}>
                    <Route index element={<Navigate replace to={'vehicles'}/>}/>
                    <Route path="vehicles" element={<Vehicles/>}/>
                    <Route path="development" element={<Development/>}/>
                    <Route path="server-error" element={<ServerError/>}/>
                    <Route path="404" element={<Error404/>}/>
                    <Route path="profile" element={<ProfileLayout/>}>
                        <Route path="personal-info" element={<PersonalInfo/>}/>
                        <Route path="security-settings" element={<SecuritySettings/>}/>
                    </Route>
                </Route>
                <Route path="/auth" element={<AuthLayoutFallback/>}>
                    <Route path="login" element={<LoginPage/>}/>
                    <Route path="forgot-password" element={<ForgotPasswordPage/>}/>
                    <Route path="new-password" element={<NewPasswordPage/>}/>
                </Route>
                <Route path="/logout" element={<LogoutFallback/>}/>
            </Routes>
        </BrowserRouter>
    );
};
