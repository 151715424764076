import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {BaseForm} from '@app/components/common/forms/BaseForm/BaseForm';
import {useAppDispatch, useAppSelector} from '@app/hooks/reduxHooks';
import {notificationController} from '@app/controllers/notificationController';
import * as S from './LoginForm.styles';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import {useSignIn} from 'react-auth-kit'
import {ApiClient} from "@app/api/client.api";
import {TENANT, Token_ExpiresIn} from "@app/config/app";
import {TokenRequest} from "@app/api/generated/client.api.generated";
import {showApiNotificationError} from "@app/utils/utils";
import {setUser} from "@app/store/slices/userSlice";
import {UserDefault} from "@app/constants/defaultUser";
import {UserModel} from "@app/domain/UserModel";
import {persistToken} from "@app/services/localStorage.service";

interface LoginFormData {
    username: string;
    password: string;
}

export const LoginForm: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const signIn = useSignIn()


    const [isLoading, setLoading] = useState(false);

    const handleSubmit = (formData: LoginFormData) => {
        setLoading(true);

        ApiClient.tokens_GetToken(TENANT, {username: formData.username, password: formData.password} as TokenRequest)
            .then((res) => {
                if (signIn(
                    {
                        token: res.token,
                        expiresIn: Token_ExpiresIn,
                        tokenType: "Bearer",
                        // refreshToken: res.refreshToken,
                        // refreshTokenExpireIn: RefreshToken_ExpireIn,
                        authState: {}
                    }
                )) {
                    persistToken(res.token);
                    ApiClient.personal_GetProfile().then(x =>{
                        dispatch(setUser({...UserDefault, firstName: x.name,  userName: x.userName, id: x.id} as UserModel));
                    })
                        .catch(err => showApiNotificationError(err))

                    navigate('/dashboard/vehicles')
                } else
                    notificationController.error({message: 'Hmmm! Щось пішло не так!'});
                return;
            })
            .catch((err) => {
                showApiNotificationError(err);
                setLoading(false);
            });

    };

    return (
        <Auth.FormWrapper>
            <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional">
                <Auth.FormTitle>{t('common.login')}</Auth.FormTitle>
                <S.LoginDescription>{t('login.loginInfo')}</S.LoginDescription>
                <Auth.FormItem
                    name="username"
                    label={t('common.userName')}
                    rules={[
                        {required: true, message: t('common.requiredField')},
                    ]}
                >
                    <Auth.FormInput placeholder={t('common.userName')}/>
                </Auth.FormItem>
                <Auth.FormItem
                    label={t('common.password')}
                    name="password"
                    rules={[{required: true, message: t('common.requiredField')}]}
                >
                    <Auth.FormInputPassword placeholder={t('common.password')}/>
                </Auth.FormItem>
                {/*<Auth.ActionsWrapper>*/}
                {/*    <BaseForm.Item name="rememberMe" valuePropName="checked" noStyle>*/}
                {/*        <Auth.FormCheckbox>*/}
                {/*            <S.RememberMeText>{t('login.rememberMe')}</S.RememberMeText>*/}
                {/*        </Auth.FormCheckbox>*/}
                {/*    </BaseForm.Item>*/}
                {/*    <Link to="/auth/forgot-password">*/}
                {/*        <S.ForgotPasswordText>{t('common.forgotPass')}</S.ForgotPasswordText>*/}
                {/*    </Link>*/}
                {/*</Auth.ActionsWrapper>*/}
                <BaseForm.Item noStyle>
                    <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
                        {t('common.login')}
                    </Auth.SubmitButton>
                </BaseForm.Item>
                {/*<BaseForm.Item noStyle>*/}
                {/*    <Auth.SocialButton type="default" htmlType="submit">*/}
                {/*        <Auth.SocialIconWrapper>*/}
                {/*            <GoogleIcon/>*/}
                {/*        </Auth.SocialIconWrapper>*/}
                {/*        {t('login.googleLink')}*/}
                {/*    </Auth.SocialButton>*/}
                {/*</BaseForm.Item>*/}
                {/*<BaseForm.Item noStyle>*/}
                {/*    <Auth.SocialButton type="default" htmlType="submit">*/}
                {/*        <Auth.SocialIconWrapper>*/}
                {/*            <FacebookIcon/>*/}
                {/*        </Auth.SocialIconWrapper>*/}
                {/*        {t('login.facebookLink')}*/}
                {/*    </Auth.SocialButton>*/}
                {/*</BaseForm.Item>*/}
                {/*<Auth.FooterWrapper>*/}
                {/*    <Auth.Text>*/}
                {/*        {t('login.noAccount')}{' '}*/}
                {/*        <Link to="/auth/sign-up">*/}
                {/*            <Auth.LinkText>{t('common.here')}</Auth.LinkText>*/}
                {/*        </Link>*/}
                {/*    </Auth.Text>*/}
                {/*</Auth.FooterWrapper>*/}
            </BaseForm>
        </Auth.FormWrapper>
    );
};
