import React from 'react';
import {Avatar, Col, Row} from 'antd';
import {Dropdown} from '@app/components/common/Dropdown/Dropdown';
import {H6} from '@app/components/common/typography/H6/H6';
import {ProfileOverlay} from '../ProfileOverlay/ProfileOverlay';
import {useAppSelector} from '@app/hooks/reduxHooks';
import {useResponsive} from '@app/hooks/useResponsive';
import * as S from './ProfileDropdown.styles';
import {toSourceImage} from '@app/utils/utils';
import UserOutlined from '@ant-design/icons/lib/icons/UserOutlined';

export const ProfileDropdown: React.FC = () => {
    const {isTablet} = useResponsive();

    const user = useAppSelector((state) => state.user.user);

    return user ? (
        <Dropdown overlay={<ProfileOverlay/>} trigger={['click']}>
            <S.ProfileDropdownHeader as={Row} gutter={[10, 10]} align="middle">
                <Col>
                    {/*<Avatar src={toSourceImage('/files/company-avatar.png')} alt="User" shape="circle" size={40} />*/}
                    <Avatar alt="User" shape="circle" size={40} icon={<UserOutlined/>}/>

                    {/*  <Avatar style={{color : '#FFBF00', backgroundColor: '#e8e8e8', fontSize:'26px'}} alt="User" shape="circle" size={40}>A</Avatar>*/}
                </Col>
                {isTablet && (
                    <Col>
                        <H6>{`${user.firstName} ${user.lastName ? user.lastName[0] : ''}`}</H6>
                    </Col>
                )}
            </S.ProfileDropdownHeader>
        </Dropdown>
    ) : null;
};
