/* eslint-disable */

import {Additionally, Client, FileParameter, VehicleAdditionally} from "@app/api/generated/client.api.generated";
import axios, {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, CancelToken} from "axios";
import {ICreateVehicleRequest, IUpdateVehicleRequest} from "@app/api/generated/models/Vehicle";
import dayjs from "dayjs";

export class ClientApiExtended extends Client {
    constructor(baseUrl?: string, instance?: AxiosInstance) {
        super(baseUrl, instance);
    }

    vehicles_IUpdate(id: string, request: IUpdateVehicleRequest, cancelToken?: CancelToken | undefined): Promise<string> {

        return this.vehicles_Update(id, request.vin, request.fullName,
            request.makeYear, request.dateOfRegistration, request.speedometer, request.fuelType,
            request.powerKw, request.powerHp, request.engineDisplacement, request.transmissionType, request.bodyType,
            request.keys, request.status, request.deliveryPaid, request.brokerPaid, request.carPaid, request.dutyPaid, request.trackingLink, request.comment, request.imageToDelete, null, request.images, cancelToken);
    }

    vehicles_ICreate(request: ICreateVehicleRequest, cancelToken?: CancelToken | undefined) {

        return this.vehicles_Create(request.vin, request.fullName, request.makeYear, request.dateOfRegistration,
            request.speedometer, request.fuelType, request.powerKw, request.powerHp, request.engineDisplacement,
            request.transmissionType, request.bodyType,
            request.keys, request.status, request.deliveryPaid, request.brokerPaid, request.carPaid, request.dutyPaid, request.trackingLink, request.comment, null, request.images, cancelToken);
    }

}