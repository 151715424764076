// import facebook from "../../assets/images/icon-facebook.svg";
// import facebook from "../../assets/images/facebook-24.svg";
// import instagram from "../../assets/images/instagram-24.svg";
// import telegram from "../../assets/images/telegram-24.svg";
import facebook from "../../assets/icons/facebook-24.svg";
import instagram from "../../assets/icons/instagram-24.svg";
import telegram from "../../assets/icons/telegram-24.svg";

export const menuData = [
  {
    id: 1,
    title: "Features",
    link: "/features", 
  },
  {
    id: 2,
    title: "Pricing",
    link: "/pricing",
  },
  {
    id: 3,
    title: "Contact",
    link: "/",
  },
];

export const btnData = {
  title: "Login",
  link: "/login",
};

export const socialData = [
  {
    id: 1,
    title: "telegram",
    image: telegram,
    link: "https://t.me/autocatchat",
  },
  {
    id: 2,
    title: "instagram",
    image: instagram,
    link: "https://www.instagram.com/auto_cat_company",
  },
  {
    id: 3,
    title: "facebook",
    image: facebook,
    link: "https://www.facebook.com/AutoCatUA/",
  },
];
