import {SearchInput, SearchInputProps} from "@app/components/common/inputs/SearchInput/SearchInput";
import React from "react";
import {VinSearch} from "@app/pages/home/src/components/VinInputSearchStyles";
import {InputRef} from "antd";

// interface VinInputSearchProps extends SearchInputProps {
//     // onSearch: (vin: string) => void;
// }

export const VinInputSearch = React.forwardRef<InputRef, SearchInputProps>(({ style,...props }, ref) => {

    return (
        <>
            <VinSearch
                ref={ref}
                // width="40%"
                // value={query}
                placeholder={'VIN'}
                // filter={
                //     <Btn
                //         size="small"
                //         // type={isFilterVisible ? 'ghost' : 'text'}
                //         type={ 'text'}
                //         aria-label="Filter"
                //         icon={<SearchOutlined />}
                //         // onClick={() => setFilterActive(!isFilterVisible)}
                //     />
                // }
                // onChange={(event) => setQuery(event.target.value)}
                // onSearch={onSearch}
                // enterButton={null}
                // addonAfter={null}
                {...props}
            />
        </>
    );
});

export default VinInputSearch;
