import React, {useState, useEffect, useRef} from "react";
import {
    FaqContainer,
    FaqWrap,
    FaqHeader,
    FaqSubHeader,
    Faq,
    FaqItemWrap,
    FaqQuestionWrap,
    FaqQuestion,
    FaqIcon,
    FaqAnswer,
    FaqAnswerWrap,
    FaqButton,
} from "./AppSectionStyles";
import VinInputSearch from "@app/pages/home/src/components/VinInputSearch";
import {Button, Descriptions, Skeleton} from "antd";
import {IVehicleInformationDto} from "@app/api/generated/client.api.generated";
import {ApiClient} from "@app/api/client.api";
import styled from "styled-components";
import ImageCarousel from "@app/pages/home/src/ImageCarousel";
import {showApiNotificationError} from "@app/utils/utils";
import VehicleInfo from "../../VehicleInfoDescriptions";
import {flushSync} from "react-dom";
import {notificationController} from "@app/controllers/notificationController";
import  cat from "../../assets/images/cat.svg"


const AppSection: React.FC = () => {

    const [loading, setLoading] = useState(false);
    const [vehicle, setVehicle] = useState<IVehicleInformationDto | undefined>();
    const ref = useRef<HTMLDivElement>(null);

    const OnSearch = (vin: string) => {
        if (!vin) {
            notificationController.warning({message: 'Введіть VIN'});
            return;
        }
        setLoading(true)

        vin = vin.trim();
        ApiClient.vehicles_Get2(vin).then(x => {

            flushSync(() => {
                setVehicle(x);
            })

            ref.current?.scrollIntoView({behavior: 'smooth'});
        }).catch((err) => {
            showApiNotificationError(err);
        }).finally(() => {
            setLoading(false);
        })
    }


    return (
        <>

            <FaqContainer>
                <FaqWrap>
                    <FaqHeader>{'Пошук по VIN'}</FaqHeader>
                    {/*<FaqSubHeader>{'sub'}</FaqSubHeader>*/}
                    <VinInputSearch loading={loading} onSearch={OnSearch}/>

                    {
                        vehicle && <>
                            <div ref={ref}><VehicleInfo vehicle={vehicle}/></div>
                        </>
                    }
                    {vehicle ? <></> : <CatBack/>}

                    {/*<Skeleton loading={loading} active/>*/}
                    {/*<FaqButton>*/}
                    {/*  <Button>{'button'}</Button>*/}
                    {/*</FaqButton>*/}

                    {
                        // vehicle?.relativeUrlImagePaths && <ImageCarousel relativePaths={vehicle.relativeUrlImagePaths}/>
                    }
                </FaqWrap>
            </FaqContainer>
        </>
    );
};


const CatWrapper = styled.div`
`;

const CatBack = styled.svg`
  background-image: url(${cat});
  background-repeat: no-repeat;
  //position: relative;
  transform: translateY(30%);
  z-index: -1;
  //width: 30vw;
  //height: 56vh;
  width: 535px;
  height: 485px;


  //max-width: 535px;
  //max-height: 485px;

  display: block;
  margin: auto;

  max-width: 65%;

  @media screen and (max-width: 900px) {

  }

  @media screen and (max-width: 670px) {

  }

  @media screen and (max-width: 450px) { //xs

  }
  
  
`


export default AppSection;
