import React from "react";
import Header from "../../components/Header/Header";
import {Main} from "../../styles/GlobalStyles";
import {brandData} from "../../data/global/brandData";
import {menuData, btnData, socialData} from "../../data/global/menuData";
import AppSection from "../../components/AppSection/AppSection";


const Home = () => {
    return (
        <>
            <Header
                brandData={brandData}
                menuData={menuData}
                btnData={btnData}
                socialData={socialData}
            />
            <Main>
                <AppSection/>
            </Main>
        </>
    );
};

export default Home;
