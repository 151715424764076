import logoPrimary from "../../assets/images/logo-bookmark.svg";
import logoSecondary from "../../assets/images/logo-bookmark-pro.svg";
import logoTertiary from "../../assets/images/logo-bookmark-footer.svg";

export const brandData = {
  name: "VIN Checker",
  logo1: logoPrimary,
  logo2: logoSecondary,
  logo3: logoTertiary
};
