import {UserModel} from "@app/domain/UserModel";
const avatarImg = process.env.REACT_APP_ASSETS_BUCKET + '/avatars/avatar5.webp';

export const UserDefault : UserModel = {
    id: '1',
    firstName: 'Chris',
    lastName:  undefined,
    // imgUrl: avatarImg,
    userName: '@john1989',
    // email: {
    //     name: 'chris.johnson@altence.com',
    //     verified: false,
    // },
    // phone: {
    //     number: '+18143519459',
    //     verified: true,
    // },
    // sex: 'male',
    // birthday: '01/26/2022',
    // lang: 'en',
    // country: 'GB',
    // city: 'London',
    // address1: '14 London Road',
    // zipcode: 5211,
    // website: 'altence.com',
    // socials: {
    //     twitter: '@altence_team',
    //     facebook: 'https://facebook.com/groups/1076577369582221',
    //     linkedin: 'https://linkedin.com/company/altence',
    // }
}