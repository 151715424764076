import { SearchInput } from 'components/common/inputs/SearchInput/SearchInput';
import styled from "styled-components";
import {lightColorsTheme} from "@app/styles/themes/light/lightTheme";
import {BREAKPOINTS, media} from "@app/styles/themes/constants";


export const VinSearch = styled(SearchInput)`
  margin-top: 2rem;
  max-width: 65%;
  
  
  .ant-input-affix-wrapper{
    border-color: #4d4c46;
  }
  
  @media screen and (max-width: 900px) {
    max-width: 75%;
  }

  @media screen and (max-width: 670px) {
     max-width: 85%;
   }

  @media screen and (max-width: 450px) { //xs
    max-width: 95%;
  }

  .ant-input-search-button{
    //border: 1px solid rgb(169, 169, 169);
    border: 1px solid #2a4050;
  }
  
  
  
  // @media only screen and ${media.lg} {
  //   max-width: 15%;
  // }
  //
  //
  // @media only screen and ${media.xs} {
  //     max-width: 95%;
  // }
  //


  // .ant-input-affix-wrapper{
  //   background-color: #ffffffd9;
  // }
  //.ant-input{
  //  background-color: #ffffffd9;
  //}
  
  //background-color: ${lightColorsTheme.background};
`;
