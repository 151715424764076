import styled from "styled-components";
import {homeVariables} from "@app/pages/home/src/styles/GlobalStyles";

export const VehicleInfoWrapper = styled.div`
  margin-top: 5rem;

  .ant-descriptions-item-label {
    background-color: rgba(255, 255, 255, 0.04);
  }

  td {
    border-right: 1px solid #f0f0f0;
  }

  //margin-bottom: 3000px;
`
