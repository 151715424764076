import { UserModel } from '@app/domain/UserModel';
import {UserDefault} from "@app/constants/defaultUser";
const avatarImg = process.env.REACT_APP_ASSETS_BUCKET + '/avatars/avatar5.webp';



export const persistToken = (token: string): void => {
  localStorage.setItem('_auth', token);
};

export const readToken = (): string | null => {
  return localStorage.getItem('_auth');
};

export const persistUser = (user: UserModel): void => {
  localStorage.setItem('user', JSON.stringify(user));
};

export const readUser = (): UserModel | null => {
  const userStr = localStorage.getItem('user');

  return userStr ? JSON.parse(userStr) : UserDefault;
};

export const deleteToken = (): void => localStorage.removeItem('_auth');
export const deleteUser = (): void => localStorage.removeItem('user');
