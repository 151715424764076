import React, {useMemo} from "react";
import {useResponsive} from "@app/hooks/useResponsive";
import * as S from "@app/pages/home/src/components/Header/Logo/Logo.styles";

import logo from 'assets/logo-cat-tr.png';

interface CatLogoProps {
    color?: string | undefined;
}

const CatLogo: React.FC<CatLogoProps> = ({color}) => {
    const { isDesktop, mobileOnly, tabletOnly } = useResponsive();

    const isCollapsible = useMemo(() => mobileOnly && tabletOnly, [mobileOnly, tabletOnly]);

    return (
        <>
            <S.SiderLogoLink to="/">
                <img src={logo} alt="AutoCat" width={56} height={60} />
                <S.BrandSpan color={color || 'var(--black)'} >Auto Cat</S.BrandSpan>
            </S.SiderLogoLink>
        </>
    );
};

export default CatLogo;
