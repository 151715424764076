import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar } from 'antd';
import { UserModel } from '@app/domain/UserModel';
import * as S from './ProfileInfo.styles';
import avatar from '@app/assets/data/company-avatar.png';
import {toSourceImage} from "../../../../utils/utils";

interface ProfileInfoProps {
  profileData: UserModel | null;
}

export const ProfileInfo: React.FC<ProfileInfoProps> = ({ profileData }) => {
  const [fullness] = useState(90);

  const { t } = useTranslation();


  // Мб вернуть аватар як лого по дефолту
  return profileData ? (
    <S.Wrapper>
      {/*<S.ImgWrapper>*/}
      {/*  <Avatar shape="circle" src={toSourceImage('/files/company-avatar.png')} alt="Profile" />*/}
      {/*</S.ImgWrapper>*/}
      {/*<S.Title>{`${profileData?.firstName} ${profileData?.lastName}`}</S.Title>*/}
      <S.Subtitle>{'@' + profileData?.userName ?? 'username'}</S.Subtitle>
      {/*<S.FullnessWrapper>*/}
      {/*  <S.FullnessLine width={fullness}>{fullness}%</S.FullnessLine>*/}
      {/*</S.FullnessWrapper>*/}
      <S.Text>{t('profile.fullness')}</S.Text>
    </S.Wrapper>
  ) : null;
};
