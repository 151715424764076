import React from "react";
import {Col, Descriptions, Divider, Row} from "antd";
import {VehicleInfoWrapper} from "./VehicleInfoDescriptionsStyles";
import {IVehicleInformationDto} from "@app/api/generated/client.api.generated";
import ImageCarousel, {ImageCarouselProps} from "@app/pages/home/src/ImageCarousel";
import Link from "antd/lib/typography/Link";
import Text from "antd/lib/typography/Text";
import {homeVariables} from "./styles/GlobalStyles";
import {DarkDivider} from "@app/pages/home/src/components/AppSection/components/DividerStyles";
import styled from "styled-components";

interface VehicleInfoProps {
    vehicle: IVehicleInformationDto
    // ref: anyx
}


const VehicleInfo: React.FC<VehicleInfoProps> = ({vehicle: vehicle}) => {
    return (<>
            <VehicleInfoWrapper>
                {/*<Descriptions title={<h2 style={{textAlign: 'left'}}>{vehicle?.vehicleFullName}</h2>} bordered*/}
                {/*              column={{sm: 2, xs: 1}}*/}
                {/*>*/}
                <Descriptions
                    title={<Title vehicleName={vehicle.fullName} relativePaths={vehicle.relativeUrlImagePaths}/>}
                    bordered
                    column={{sm: 2, xs: 1}}
                >
                    {/*<Descriptions.Item label='Full Name'>{vehicle?.vehicleFullName}</Descriptions.Item>*/}
                    <Descriptions.Item label='Рік випуску'>{vehicle?.makeYear}</Descriptions.Item>
                    <Descriptions.Item
                        label='Перша реєстрація'>{vehicle?.dateOfRegistration?.format('DD.MM.YYYY')}</Descriptions.Item>
                    <Descriptions.Item
                        label='Показання одометра'>{withSuffix(vehicle?.speedometer, 'км')}</Descriptions.Item>
                    <Descriptions.Item label='Тип палива'>{vehicle?.fuelType}</Descriptions.Item>
                    <Descriptions.Item
                        label='Потужність'>{(vehicle.powerKw || vehicle.powerHp) ? `${vehicle.powerKw} кВт / ${vehicle.powerHp} к.с.` : null}</Descriptions.Item>
                    <Descriptions.Item label="Об'єм двигуна">{vehicle?.engineDisplacement &&
                        <span>{vehicle?.engineDisplacement} <span>см<sup>3</sup></span></span>}</Descriptions.Item>
                    <Descriptions.Item label='Коробка передач'>{vehicle?.transmissionType}</Descriptions.Item>
                    <Descriptions.Item label='Тип кузова'>{vehicle?.bodyType}</Descriptions.Item>
                    <Descriptions.Item label='Ключі'>{vehicle?.keys}</Descriptions.Item>

                    <Descriptions.Item label='Статус'>{vehicle?.status}</Descriptions.Item>
                    <Descriptions.Item label='Доставка'>{toStatusString(vehicle?.deliveryPaid)}</Descriptions.Item>
                    <Descriptions.Item label='Брокер'>{toStatusString(vehicle?.brokerPaid)}</Descriptions.Item>
                    <Descriptions.Item label='Авто'>{toStatusString(vehicle?.carPaid)}</Descriptions.Item>
                    <Descriptions.Item label='Мито'>{toStatusString(vehicle?.dutyPaid)}</Descriptions.Item>
                    <Descriptions.Item label='Трекер'>{
                        vehicle.trackingLink ?
                            <Link href={vehicle.trackingLink} code target='_blank'>Відстежити</Link> :
                            <Text style={{color: homeVariables.textMainColor}} type='secondary'>&#8212;</Text>
                    }
                    </Descriptions.Item>
                    {vehicle.comment ? <>
                        <Descriptions.Item label=''>{}</Descriptions.Item>
                        <Descriptions.Item label='Коментар'>{vehicle.comment}</Descriptions.Item>
                    </> : <></>}
                </Descriptions>
            </VehicleInfoWrapper>

            <div style={{height: '5rem'}}>

            </div>
        </>
    );

}

interface TitleProps {
    relativePaths: string[] | undefined;
    vehicleName: string
}

const Title: React.FC<TitleProps> = ({relativePaths, vehicleName}) => {

    return (
        <TitleWrapper>
            <Row>
                {
                    relativePaths ?
                        <><Col span={24}>
                            <Divider>
                                <h1 style={{textAlign: 'center', marginBottom: '1rem'}}>{vehicleName}</h1>
                            </Divider>
                        </Col>
                            <Col span={24}>
                                <ImageCarousel relativePaths={relativePaths}></ImageCarousel>
                            </Col>
                        </>
                        :
                        <>
                            <Divider>
                                <h1 style={{textAlign: 'center'}}>{vehicleName}</h1>
                            </Divider>
                        </>
                }

            </Row>
        </TitleWrapper>
    );

}


const TitleWrapper = styled.div`
  .ant-divider-horizontal.ant-divider-with-text {
    border-top-color: rgba(255, 255, 255, .12);
  }
`


const withSuffix = (value: string | number | undefined | null, suffix: string): string | null => {
    if (!value)
        return null;
    return value + ' ' + suffix;
}

const toStatusString = (value: boolean) => {
    return value ? 'Оплачено' : 'Не оплачено';
}

export default VehicleInfo;