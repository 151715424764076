import React from "react";
import styled from "styled-components";
import {Settings} from "@ant-design/react-slick";
import {Image} from "antd";
import {Carousel} from "@app/components/common/Carousel/Carousel";
import {toSourceImage} from "@app/utils/utils";
import Slider from 'react-slick';


const CarouselWrapper = styled.div`
  max-height: 50vh;
  max-width: 100%;

  margin-bottom: 4rem;


  .slick-slide {
    text-align: center;
  }

  img {
    objectFit: 'contain';
    max-height: 50vh;


    max-width: 100%;

    border-radius: 8px;

    /* offset-x | offset-y | blur-radius | spread-radius | color */
    box-shadow: 0px 6px 10px 0px hsla(0, 0%, 0%, 0.14),
    0px 1px 18px 0px hsla(0, 0%, 0%, 0.12),
    0px 3px 5px -1px hsla(0, 0%, 0%, 0.2);

  }

  .ant-image-mask {
    border-radius: 8px;
  }

  .slick-dots li button:before {
    color: rgba(255,255,255);
  }


`


export const SliderArrow = styled.div`
  z-index: 1;
  width: unset;
  height: unset;

  &.slick-prev {
    left: 1.25rem;
  }

  &.slick-next {
    right: 1.25rem;
  }

  &:before {
    color: var(--primary-color) !important;
    font-size: 2rem !important;
    //position: absolute;
    //left: -6px;
    //top: -6px;
  }
`;


const NextArrow = (props: any) => {
    const {className, style, onClick} = props;
    return <SliderArrow className={className} style={{...style, display: 'block'}} onClick={onClick}/>;
};
const PrevArrow = (props: any) => {
    const {className, style, onClick} = props;
    return <><SliderArrow className={className} style={{...style, display: 'block'}} onClick={onClick}/></>;
};


const settings: Settings = {

    // dots: true,
    arrows: true,
    infinite: false,
    adaptiveHeight: true,
    nextArrow: <NextArrow/>,
    prevArrow: <PrevArrow/>,
    // fade: true,
    // lazyLoad: 'progressive'
    // centerPadding: "60px",
    slidesToShow: 1,
    // slidesToScroll: 1,
    dots: true,
    centerMode: false
};


export interface ImageCarouselProps {
    relativePaths: string[]
}

const settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3
};

const ImageCarousel: React.FC<ImageCarouselProps> = ({relativePaths}) => {

    return (
        <>
            <CarouselWrapper>
                <Image.PreviewGroup>
                    <Carousel {...settings}>
                        {
                            relativePaths.map((x, id) => (<>
                                <Image key={id} src={toSourceImage(x)}></Image>
                            </>))
                        }
                    </Carousel>
                </Image.PreviewGroup>
            </CarouselWrapper>

        </>

    );
}

export default ImageCarousel;