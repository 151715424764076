import React, {useEffect, useRef} from 'react';
import {GlobalStyles} from "@app/pages/home/src/styles/GlobalStyles";
import Home from "@app/pages/home/src/pages/Home/Home";
import {Helmet} from "react-helmet";
import {useAppSelector} from "@app/hooks/reduxHooks";

const HomePage: React.FC = () => {
    const root = useRef(document.querySelector(':root'));
    const theme = useAppSelector((state) => state.theme.theme);

    useEffect(() => {
        const html = root.current;

        if (theme === 'light') {
            if (html) {
                html.setAttribute('data-theme', 'dark');
            }
        }
        return () => {
            if (theme === 'light') {
                if (html) {
                    html.setAttribute('data-theme', 'light');
                }
            }
        }
    })

    return (
        <>
            <Helmet>
                <title>Auto Cat &#8212; Автомобiлi з Європи</title>
            </Helmet>
            <GlobalStyles/>
            {/*<GlobalStyle/>*/}
            <Home/>
        </>
    );
};

export default HomePage;

